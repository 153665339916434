import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useLocation } from 'react-use';
import { M140AnchorModule } from '~/lib';
import { breakpoints } from '~/theme/breakpoints';

const AnchorElement = styled.div({
    visibility: 'hidden',
    height: 0,

    // We use scrollMarginTop to dictate how much space above the anchor element is offset.
    // This is to allow us to get over the fixed header on different breakpoints.
    scrollMarginTop: 250,
    [breakpoints.lg]: { scrollMarginTop: 100 },
});

export const M140Anchor = ({ identifier }: M140AnchorModule) => {
    const { hash } = useLocation();

    useEffect(() => {
        const sanitizedHash = hash?.replace('#', '');

        if (sanitizedHash !== identifier) return;

        const element = document.querySelector(`#${identifier}`);

        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'auto' });
            }, 300);
        }
    }, []);

    return <AnchorElement id={identifier} />;
};
