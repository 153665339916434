import React, { ComponentType, FC } from 'react';
import { ErrorBoundary } from './ErrorBoundary';

/**
 * HOC to wrap the export, provide an optional fallback component
 * @example
 * export default withErrorBoundary(MyComponent, <ErrorBoundaryFallback description="This content is currently not available"/>)
 */
export function withErrorBoundary<Props extends JSX.IntrinsicAttributes = {}>(
    WrappedComponent: ComponentType<Props>,
    FallBack?: FC<unknown> | JSX.Element
): FC<Props> {
    return (props: Props) => (
        <ErrorBoundary fallback={FallBack}>
            <WrappedComponent {...props} />
        </ErrorBoundary>
    );
}
