import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { mq } from '~/lib';
import { ModuleSpace } from '~/lib/data-contract';
import { ModuleContainerProps } from './ModuleContainer';

type SpaceingValues = keyof Theme['space'] | undefined;

export const spacings: Record<ModuleSpace, SpaceingValues> = {
    xs: '4',
    small: '5',
    default: '8',
    none: undefined,
};

export const spacingsMobile: Record<ModuleSpace, SpaceingValues> = {
    xs: '2',
    small: '3',
    default: '6',
    none: undefined,
};

export const spacingValue = (spacing: ModuleSpace, theme: Theme) => {
    const value = spacings[spacing];
    return value ? theme.space[value] : 0;
};
export const spacingValueMobile = (spacing: ModuleSpace, theme: Theme) => {
    const value = spacingsMobile[spacing];
    return value ? theme.space[value] : 0;
};

export const StyledModule = styled.div<ModuleContainerProps>(
    ({ theme, spacingTop = 'default', spacingBottom = 'default' }) => ({
        paddingTop: spacingValue(spacingTop, theme),
        paddingBottom: spacingValue(spacingBottom, theme),
        margin: '0 auto',
        [mq(0, 'md')]: {
            paddingTop: spacingValueMobile(spacingTop, theme),
            paddingBottom: spacingValueMobile(spacingBottom, theme),
        },
    }),
    ifProp('backgroundColor', ({ backgroundColor }) => ({
        backgroundColor,
    }))
);
