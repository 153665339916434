import dynamic from 'next/dynamic';
import React from 'react';
import { Modules } from '~/lib/data-contract';
import { ErrorBoundary } from '~/shared/utils/errorBoundary';
import { ErrorFallbackContent } from '../ErrorFallbackContent/ErrorFallbackContent';
import { M140Anchor } from '../M140AnchorModule/M140AnchorModule';

const M10Hero = dynamic(() => import('../M10Hero'));
const M20Categories = dynamic(() => import('../M20Categories'));
const M21ReferencedCategories = dynamic(() => import('../M21ReferencedCategories'));
const M60USP = dynamic(() => import('../M60USP'));
const M70Media = dynamic(() => import('../M70Media'));
const M75MediaText = dynamic(() => import('../M75MediaText'));
const M80MediaTextBundle = dynamic(() => import('../M80MediaTextBundle'));
const M90Quote = dynamic(() => import('../M90Quote'));
const M100RichText = dynamic(() => import('../M100RichText'));
const M120Accordion = dynamic(() => import('../M120Accordion'));
const M30ProductCarousel = dynamic(() => import('../M30ProductCarousel'));
const M31RaptorProductCarousel = dynamic(() => import('../M31RaptorProductCarousel'));
const M130CustomScript = dynamic(() => import('../M130CustomScript'));

type DynamicBlockProps = {
    element: Modules;
    index?: number;
    priority: boolean;
    productId?: string;
    productBrand?: string;
    productCategory?: string;
};

export const DynamicBlock = ({ element, index, ...rest }: DynamicBlockProps) => {
    return (
        <ErrorBoundary fallback={<ErrorFallbackContent />}>
            {getType({ element, index, ...rest })}
        </ErrorBoundary>
    );
};

export const getType = ({ element, index, ...rest }: DynamicBlockProps) => {
    switch (element.type) {
        case 'm10HeroModule':
            return (
                <M10Hero
                    {...element}
                    spacingTop={index === 0 && element.spacingTop ? element.spacingTop : 'none'}
                    {...rest}
                />
            );

        case 'm20CategoriesModule':
            return <M20Categories {...element} {...rest} />;

        case 'm21ReferencedCategoriesModule':
            return <M21ReferencedCategories {...element} {...rest} />;
        case 'm30ProductCarouselModule':
            return <M30ProductCarousel {...element} {...rest} />;
        case 'm31RaptorCarouselModule':
            return <M31RaptorProductCarousel {...element} {...rest} />;
        case 'm60UspModule':
            return <M60USP {...element} {...rest} />;

        case 'm70MediaModule':
            return <M70Media {...element} {...rest} />;

        case 'm75MediaAndTextModule':
            return <M75MediaText {...element} {...rest} />;

        case 'm80MediaAndTextBundleModule':
            return <M80MediaTextBundle {...element} {...rest} />;

        case 'm90QuoteModule':
            return <M90Quote {...element} {...rest} />;

        case 'm100RichTextModule':
            return <M100RichText {...element} {...rest} />;

        case 'm120AccordionListModule':
            return <M120Accordion {...element} {...rest} />;
        case 'm130CustomScript':
            return <M130CustomScript {...element} {...rest} />;
        case 'm140AnchorModule':
            return <M140Anchor {...element} {...rest} />;

        default:
            return null;
    }
};
