import { useMemo } from 'react';
import { usePotentialThemeColor } from '~/theme/hooks/usePotentialThemeColor';
import { PotentialThemeColorType } from '~/theme/model/themeModel';
import { getTextColor } from '~/theme/utils/getTextColor';
// import { getTextColor, PotentialThemeColorType, usePotentialThemeColor } from '~/theme';

type useModuleThemeProps = {
    backgroundColor?: string | PotentialThemeColorType;
    textColor?: string | PotentialThemeColorType;
};

export const useModuleTheme = ({ backgroundColor, textColor }: useModuleThemeProps) => {
    const backgroundColorValue = usePotentialThemeColor(backgroundColor || '');
    const textColorValue = usePotentialThemeColor(textColor || '');

    // Get text color from background color if not set
    const safeTextColorValue = useMemo(() => textColorValue || getTextColor(backgroundColorValue), [
        backgroundColorValue,
        textColorValue,
    ]);

    return {
        backgroundColor: backgroundColorValue,
        textColor: safeTextColorValue,
    };
};
