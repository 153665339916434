import React from 'react';
import { Modules } from '~/lib/data-contract';
import { DynamicBlock } from '../DynamicBlock';

type Props = {
    elements: Modules[];
    preventPriority?: boolean;
    productId?: string;
    productBrand?: string;
    productCategory?: string;
};

export const DynamicBlockList = ({
    elements,
    preventPriority,
    productId,
    productBrand,
    productCategory,
}: Props) => {
    if (!elements?.length) {
        return null;
    }

    return (
        <>
            {elements.map((element, index) => (
                <DynamicBlock
                    key={element.id}
                    element={element}
                    index={index}
                    priority={preventPriority ? false : index < 3}
                    productId={productId}
                    productBrand={productBrand}
                    productCategory={productCategory}
                />
            ))}
        </>
    );
};
