import React, { forwardRef, ReactNode } from 'react';
import { ModuleSpacing } from '~/lib/data-contract';
import { MaxWidth } from '~/shared/components';
import { Gutter } from '~/shared/components/Gutter';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { StyledModule } from './styled';

export type ModuleContainerProps = {
    /**
     * Adds a solid background color to the entire module space.
     * Edge to edge.
     */
    backgroundColor?: string;

    spacingTop?: ModuleSpacing['spacingTop'];
    spacingBottom?: ModuleSpacing['spacingBottom'];

    /**
     * Prevents capping the width to the max content widht
     */
    fullWidth?: boolean;

    /**
     * Add side gutters to the module. Usefull if the content
     * is not intended to go full width on mobile. eg. text modules
     */
    hasGutter?: boolean;

    children: ReactNode;
    className?: string;
};

export const ModuleContainer = forwardRef<HTMLDivElement, ModuleContainerProps>((props, ref) => {
    const {
        fullWidth,
        children,
        spacingTop,
        spacingBottom,
        className,
        hasGutter,
        backgroundColor: initialBackgroundColor,
    } = props;
    const { backgroundColor } = useModuleTheme({ backgroundColor: initialBackgroundColor });

    const content = hasGutter ? <Gutter children={children} /> : children;

    return (
        <StyledModule
            ref={ref}
            className={className}
            backgroundColor={backgroundColor}
            spacingBottom={spacingBottom}
            spacingTop={spacingTop}
        >
            {fullWidth ? content : <MaxWidth children={content} />}
        </StyledModule>
    );
});
